import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter';

export default ({node}) => {
  if (!node || !node.code) { return null }
  const {language, code} = node 
  return (
    <SyntaxHighlighter language={language || 'text'}>
      {code}
    </SyntaxHighlighter>
  )
}

//props => (
//      <pre data-language={props.node.language} style={{"background-color": "#040609", "color": "white"}}>
//        <code>{props.node.code}</code>        
//      </pre>
//    )