//import React from 'react'
import React, { Component } from 'react';
import Figure from './Figure'
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'
import Code from './serializeCode'
import Markdown from './serializeMarkdown'


const serializers = {
  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    mainImage: Figure,
    code: Code,
    markdownReference: Markdown,
    youtube: ({node}) => {
      const { url } = node
      const id = getYouTubeId(url)
      return (<YouTube videoId={id} />)
    }
  }
  
}

export default serializers