import React from 'react'
const ReactMarkdown = require('react-markdown')
const htmlParser = require('react-markdown/plugins/html-parser')

// See https://github.com/aknuds1/html-to-react#with-custom-processing-instructions
// for more info on the processing instructions
const parseHtml = htmlParser({
  isValidNode: node => node.type !== 'script',
  processingInstructions: [/* ... */]
})


    
export default ({node}) => {
  if (!node || !node.markdownField) { return null }
  const {markdownField} = node 
  return (
    <ReactMarkdown
      source={markdownField}
      escapeHtml={false}
      astPlugins={[parseHtml]}
    />
  )
}

//props => (
//      <pre style={{"background-color": "#040609", "color": "white"}}>
//        <code>{props.node.markdownField}</code>
//      </pre>
//    )