import React, {useEffect} from 'react'
import { useSelector } from "react-redux"

import styles from './container.module.css'

const Container = ({children}) => {
    
    const theme = useSelector(state => state.theme)
    const getThemeClass = (theme) => {
        return (theme == "light"? styles.light: styles.dark)
    }
    
        
  return (
      <div id="containerPreview"
          className={getThemeClass(theme)}
          styles={{width:"100%"}}>
          <div 
            className={styles.root}>
            {children}
          </div>
      </div>
  )
}

export default Container
